import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import Popover from '@mui/material/Popover';
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Unpaid: "text-red-700 bg-red-50 ring-red-600/10",
};

// Define the cards and their corresponding keys
const cardData = [
  { title: "Connected", key: "connected", image: "/link.png" },
  { title: "First Lesson Complete", key: "firstLessonComplete", image: "/first.png" },
  { title: "Won", key: "wonJobs", image: "/won.png" },
  { title: "Packages", key: "packageCount", image: "/package.png" }, // Updated key for package count
  { title: "Lost - Pre Connection", key: "lostPreConnection", image: "/lostpre.png" },
  { title: "Lost - Post Connection", key: "lostPostConnection", image: "/lostpost.png" },
  { title: "Commission", key: "commission", image: "/commission.png", role: "Admin" },
  { title: "My Commission", key: "commission", image: "/commission.png", role: "Client Manager" },
];


const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = dayjs();
      const lastMonth = today.subtract(1, "month");
      return [lastMonth.startOf("month"), lastMonth.endOf("month")];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "This Year",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today.endOf("year")];
    },
  },
  {
    label: "Last Year",
    getValue: () => {
      const today = dayjs();
      const lastYear = today.subtract(1, "year");
      return [lastYear.startOf("year"), lastYear.endOf("year")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

const Leaderboard = () => {
  const [clientManagers, setClientManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null); // Add state for the logged-in user

  
  const [selectedManager, setSelectedManager] = useState(null); // State for selected manager
  const [monthlyCommission, setMonthlyCommission] = useState([]); // To store the commission data for each month

  const [dateRange, setDateRange] = useState({
    startDate: dayjs().startOf("month"),
    endDate: dayjs().endOf("month"),
  });

  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }

        const response = await axios.get("/api/current-user", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoggedInUser(response.data);
        console.log("Logged in user:", response.data);
      } catch (error) {
        console.error("Error fetching logged-in user:", error);
      }
    };

    fetchLoggedInUser();
  }, []);

  const fetchLeaderboardData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/leaderboard", {
        params: {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        },
      });
      console.log("Leaderboard data:", response.data); // Log the response
      setClientManagers(response.data);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error.message);
    } finally {
      setLoading(false);
    }
  }, [dateRange]);
  

  const handleDateRangeChange = (newValue) => {
    if (newValue && newValue[0] && newValue[1]) {
      setDateRange({
        startDate: newValue[0].startOf("day"),
        endDate: newValue[1].endOf("day"),
      });
    }
  };

  const fetchYearlyCommission = useCallback(async (managerEmail) => {
    if (!managerEmail) return;
    setLoading(true);
  
    try {
      const response = await axios.get(`/commission/yearly/${managerEmail}`);
      if (response.headers['content-type'].includes('application/json')) {
        console.log('Commission Data:', response.data);  // Log the full response for debugging
        setMonthlyCommission(response.data || []);  // Store the array of commission per month
      } else {
        console.error("Unexpected response format:", response);
        setMonthlyCommission([]);
      }
    } catch (error) {
      console.error("Error fetching yearly commission:", error);
      setMonthlyCommission([]);
    } finally {
      setLoading(false);
    }
  }, []);
  
  
  

  const handleTogglePaidStatus = async (manager) => {
    try {
      const selectedMonth = dateRange.startDate.format('MMMM YYYY');

      await axios.post(`/api/update-commission-status`, {
        manager: manager.manager,
        status: manager.paidStatus === "Paid" ? "Unpaid" : "Paid",
        amount: manager.commission,
        month: selectedMonth,
      });

      setClientManagers((prevManagers) =>
        prevManagers.map((m) =>
          m.manager === manager.manager
            ? { ...m, paidStatus: m.paidStatus === "Paid" ? "Unpaid" : "Paid" }
            : m
        )
      );
    } catch (error) {
      console.error("Error updating commission status:", error.message);
    }
  };


  const handleManagerChange = (event) => {
    const managerEmail = event.target.value;  // This is the correct email field
    console.log("Selected Manager Email: ", managerEmail);
    setSelectedManager(managerEmail);
    fetchYearlyCommission(managerEmail);  // Pass the manager email correctly
  };
  

  const isWholeMonth = (startDate, endDate) => {
    return (
      startDate.date() === 1 && endDate.isSame(startDate.endOf('month'), 'day')
    );
  };


  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }


  const fetchCommissionPayments = useCallback(async () => {
    if (!isWholeMonth(dateRange.startDate, dateRange.endDate)) {
      // Set 'Unpaid' if it's not a whole month
      setClientManagers((prevManagers) =>
        prevManagers.map((manager) => ({
          ...manager,
          paidStatus: 'Unpaid',
          amount: 0,
        }))
      );
      return;
    }

    try {
      const month = dateRange.startDate.format('MMMM YYYY');
      const response = await axios.get('/api/commission-payments', {
        params: { month },
      });
      const paymentStatuses = response.data;

      setClientManagers((prevManagers) =>
        prevManagers.map((manager) => {
          const paymentStatus = paymentStatuses.find(
            (status) => status.client_manager === manager.manager
          );
          return {
            ...manager,
            paidStatus: paymentStatus ? paymentStatus.status : 'Unpaid',
            amount: paymentStatus ? paymentStatus.amount : 0,
          };
        })
      );
    } catch (error) {
      console.error('Error fetching commission payments:', error.message);
    }
  }, [dateRange]);

  useEffect(() => {
    console.log('Client Managers Data:', clientManagers); // Log the entire clientManagers array
  }, [clientManagers]);
  

  useEffect(() => {
    fetchLeaderboardData();
    fetchCommissionPayments();
  }, [fetchLeaderboardData, fetchCommissionPayments]);


   // Helper function to dynamically assign status styles
   const getStatusClass = (status) => {
    return status === "Paid" ? statuses.Paid : statuses.Unpaid;
  };

  return (
    <div className="mx-auto p-4">
      <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900 mb-4">
        Leaderboard
      </h1>

      <button
        onClick={() => setShowDatePicker((prev) => !prev)}
        className="px-4 py-2 border rounded mb-4"
      >
        {dateRange.startDate.format("MMM DD, YYYY")} -{" "}
        {dateRange.endDate.format("MMM DD, YYYY")}
      </button>

      {/* Popover to show the StaticDateRangePicker */}
      <Popover
        open={showDatePicker}
        anchorEl={anchorEl}
        onClose={() => setShowDatePicker(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="p-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              value={[dateRange.startDate, dateRange.endDate]}
              onChange={handleDateRangeChange}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                },
                actionBar: { actions: [] },
              }}
              calendars={2}
            />
          </LocalizationProvider>
        </div>
      </Popover>

      {loading ? (
        <div className="text-center">
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="spinner-border animate-spin inline-block w-12 h-12 border-8 rounded-full border-t-blue-500 border-blue-300 mb-4"></div>
            <p className="text-xl font-semibold text-center">
              Loading leaderboard...
            </p>
          </div>
        </div>
      ) : (
        <ul
  role="list"
  className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
>
  {cardData
    .filter(({ title, role }) => {
      // Remove "Commission" and "My Commission" cards
      return title !== "Commission" && title !== "My Commission";
    })
    .map(({ title, key, image }) => (
      <li
        key={title}
        className="overflow-hidden rounded-xl border border-gray-200"
      >
        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          <img
            alt={title}
            src={image}
            className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
          />
          <div className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </div>
        </div>
        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          {clientManagers.map((managerData) => (
            <div
              key={managerData.manager}
              className="flex justify-between gap-x-4 py-3"
            >
              <dt className="flex items-center gap-x-2 text-gray-500">
                <img
                  src={managerData.profile_image}
                  alt={managerData.manager_first_name}
                  className="h-6 w-6 rounded-full object-cover"
                />
                {managerData.manager_first_name || managerData.manager}
              </dt>
              <dd className="text-gray-700">
                {managerData[key] || 0}
              </dd>
            </div>
          ))}
        </dl>
      </li>
    ))}
</ul>


      )}

      
        {/* Select Manager Dropdown */}
        <div className="mb-4 mt-4">
        <label htmlFor="clientManager" className="block text-sm font-medium text-gray-700">
          Select Client Manager
        </label>
        <select
  id="clientManager"
  name="clientManager"
  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  value={selectedManager || ""}
  onChange={handleManagerChange}
>
  <option value="">Select Manager</option>
  {clientManagers.map((managerData) => {
    console.log('Manager Data:', managerData); // Log each manager data object
    return (
      <option key={managerData.id} value={managerData.manager}>
        {managerData.manager_first_name || "Unknown"} {managerData.last_name || ""}
      </option>
    );
  })}
</select>


      </div>

    {/* Commission Table */}
{loading ? (
  <div className="text-center">
    <p className="text-xl font-semibold">Loading commission data...</p>
  </div>
) : selectedManager && Array.isArray(monthlyCommission) && monthlyCommission.length > 0 ? (
  <div className="w-full overflow-hidden rounded-xl border border-gray-200 mb-6">
    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
      <img alt="Commission" src="/commission.png" className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10" />
      <div className="text-lg font-medium leading-6 text-gray-900">Monthly Commission</div>
    </div>
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Month</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Commission</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
  {monthlyCommission.map((commissionData) => {
    return (
      <tr key={commissionData.month_year}>
        {/* Display month_year exactly as it comes from the API */}
        <td className="px-6 py-4 whitespace-nowrap text-gray-700">{commissionData.month_year}</td>
        <td className="px-6 py-4 whitespace-nowrap text-gray-700">
          £{commissionData.total_commission ? parseFloat(commissionData.total_commission).toFixed(2) : "0.00"}
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span className={`ml-4 px-2 py-1 rounded text-xs font-medium ${getStatusClass("Unpaid")}`}>
            Unpaid
          </span>
        </td>
      </tr>
    );
  })}
</tbody>



      </table>
    </div>
  </div>
) : (
  <div className="text-center">
    <p className="text-xl font-semibold">No commission data available for the selected manager.</p>
  </div>
)}


  
    </div>

    
  );
};

export default Leaderboard;