"use client";

import { useState, useEffect, Fragment  } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { Transition, Dialog, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";


import { EllipsisHorizontalIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import ClientDialog from "./ClientDialog";
import EnquiryDialog from "./EnquiryDialog";
import { Link } from "react-router-dom";
import { formatDistanceToNow, parseISO } from "date-fns";


const initialData = {
  tasks: {},
  columns: {
    "column-1": { id: "column-1", title: "New Enquiry", taskIds: [] },
    "column-2": { id: "column-2", title: "Contacted", taskIds: [] },
    "column-3": { id: "column-3", title: "Searching For Tutor", taskIds: [] },
    "column-4": { id: "column-4", title: "Profile Sent", taskIds: [] },
 
  },
  columnOrder: [
    "column-1",
    "column-2",
    "column-3",
    "column-4",
   
  ],
};

const timeSince = (date) => {
  if (!date) {
    return "Unknown time"; // Return early if the date is undefined or null
  }
  try {
    const parsedDate = parseISO(date); // Ensure date is parsed correctly
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid date:", date);
      return "Unknown time"; // Handle invalid dates gracefully
    }
    return formatDistanceToNow(parsedDate, { addSuffix: true });
  } catch (error) {
    console.error("Error parsing date:", date, error);
    return "Unknown time"; // Handle any errors
  }
};









const statuses = {
  "New Enquiry": "text-green-700 bg-green-50 ring-green-600/20",
  "Contacted": "text-yellow-700 bg-yellow-50 ring-yellow-600/20",
  "Searching For Tutor": "text-blue-700 bg-blue-50 ring-blue-600/20",
  "Profile Sent": "text-purple-700 bg-purple-50 ring-purple-600/20",
 
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Pipeline = () => {
  const [state, setState] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState(""); // Search term state

  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [isEnquiryDialogOpen, setIsEnquiryDialogOpen] = useState(false);
  const [clients, setClients] = useState([]);

  const [users, setUsers] = useState([]);
  const [expandedCards, setExpandedCards] = useState({});
  const [visibleTasksCount, setVisibleTasksCount] = useState(10); // Show 10 initially
  const [showColumns, setShowColumns] = useState(false); // Initialize to false to hide columns

  const [filter, setFilter] = useState("all");
  const [clientManagerFilter, setClientManagerFilter] = useState("all");
  const [loggedInUser, setLoggedInUser] = useState(null); // Store the current logged-in user
  const [showLimitModal, setShowLimitModal] = useState(false); // State to control modal visibility
  const [isEnquiryInfoModalOpen, setIsEnquiryInfoModalOpen] = useState(false);
  const [enquiryInfo, setEnquiryInfo] = useState({ frequency: '', details: '' });
  
  

  const [takenLeads, setTakenLeads] = useState([]);

  


  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }

        const response = await axios.get("/api/current-user", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoggedInUser(response.data);
        console.log("Logged in user:", response.data);
      } catch (error) {
        console.error("Error fetching logged-in user:", error);
      }
    };

    fetchLoggedInUser();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get("/api/clients");
        setClients(response.data); // Make sure the data is correctly set
        console.log("Clients fetched in Pipeline:", response.data); // Check the fetched data
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
  
    fetchClients();
  }, []);
  

  useEffect(() => {
    if (!loggedInUser) {
      console.log("No logged-in user found yet.");
      return; // Prevent running fetchEnquiries() before loggedInUser is available
    }

    console.log("Logged in user is ready:", loggedInUser);
    fetchEnquiries();
    fetchUsers();
  }, [filter, clientManagerFilter, loggedInUser]);

  const toggleCardExpand = (taskId, forceExpand = false) => {
    setExpandedCards((prev) => ({
      ...prev,
      [taskId]: forceExpand ? true : !prev[taskId], // Toggle expanded state unless forced to expand
    }));
  
    const task = state.tasks[taskId];
    if (task && task.details) {
      console.log(`Client Manager: ${task.details.clientManager}`);
      console.log(`Job ID: ${task.details.job_id}`);
    } else {
      console.log("Task details not found");
    }
  };
  
  
  

  // Increases the visible task count by 10 when 'Load More' is clicked
  const loadMoreTasks = () => {
    setVisibleTasksCount((prevCount) => prevCount + 10);
  };

  // Filter tasks based on the search term
  const filteredTasks = (tasks) => {
    return tasks.filter((task) => {
      const taskDetails = task.details || {};
      const clientName = taskDetails.clientName || "";
      const jobId = taskDetails.job_id || "";
      const email = taskDetails.email || "";
      const phone = taskDetails.phone || "";

      // Check if any field contains the search term (case-insensitive)
      return (
        clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        jobId.toString().includes(searchTerm) ||
        email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        phone.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/api/users"); // Replace with your actual endpoint
      setUsers(response.data);
      console.log("Users (Client Managers) fetched:", response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getColumnKeyFromStatus = (status) => {
    switch (status) {
      case "New Enquiry":
        return "column-1";
      case "Contacted":
        return "column-2";
      case "Searching For Tutor":
        return "column-3";
      case "Profile Sent":
        return "column-4";
     
      default:
        return "column-1"; // fallback to the first column for any unknown status
    }
  };

  const fetchEnquiries = async () => {
    try {
      const [enquiriesResponse, clientsResponse] = await Promise.all([
        axios.get("/api/enquiries"),
        axios.get("/api/clients"),
      ]);
  
      // Extract the data from the responses
      let enquiries = enquiriesResponse.data;
      const clients = clientsResponse.data;
  
      const loggedInUserEmail = loggedInUser?.email;
  
      // **Filter out enquiries with unwanted statuses**
      const allowedStatuses = ['New Enquiry', 'Contacted', 'Searching For Tutor', 'Profile Sent'];
enquiries = enquiries.filter((enquiry) => 
  allowedStatuses.includes(enquiry.status) && enquiry.salesbook_status !== 'Lost Pre-Tutor'
);

  
      // Process the enriched enquiries with client information
      const enrichedEnquiries = enquiries.map((enquiry) => {
        const client = clients.find((client) => client.id === enquiry.client_id);
        const clientName = client ? `${client.first_name} ${client.last_name}` : "Unknown Client";
        const clientManager = client ? client.client_manager : "Unknown Manager";
        const jobId = enquiry.job_id;
        const tutorcruncher_id = client ? client.tutorcruncher_id : null;
  
        return {
          ...enquiry,
          clientName,
          clientManager,
          job_id: jobId,
          tutorcruncher_id, // Ensure tutorcruncher_id is added here
        };
      });
  
      // Apply filter logic for leads and clients
      let filteredEnquiries = enrichedEnquiries;
      if (filter === "new") {
        filteredEnquiries = filteredEnquiries.filter(
          (enquiry) => !enquiry.tutorcruncher_id
        );
      } else if (filter === "existing") {
        filteredEnquiries = filteredEnquiries.filter(
          (enquiry) => enquiry.tutorcruncher_id
        );
      }
  
      // Allow Admins and Assistants to filter by client manager
if (["Admin", "Assistant"].includes(loggedInUser?.role)) {
  if (clientManagerFilter !== "all") {
    filteredEnquiries = filteredEnquiries.filter((enquiry) => {
      const client = clients.find(
        (client) => client.id === enquiry.client_id
      );
      return client?.client_manager === clientManagerFilter;
    });
  }
} else if (loggedInUser?.role === "Client Manager") {
  // Include enquiries assigned to the logged-in client manager or unassigned
  filteredEnquiries = filteredEnquiries.filter((enquiry) => {
    const client = clients.find(
      (client) => client.id === enquiry.client_id
    );
    return client?.client_manager === loggedInUserEmail || !client?.client_manager;
  });
}

  
      // Update the state with the filtered enquiries
      const newTasks = {};
      const newColumns = { ...initialData.columns };
  
      filteredEnquiries.forEach((enquiry) => {
        const columnKey = getColumnKeyFromStatus(enquiry.status);
        if (!newTasks[enquiry.id]) {
          newTasks[enquiry.id] = {
            id: enquiry.id.toString(),
            content: enquiry.clientName,
            details: {
              ...enquiry,
            },
          };
  
          if (!newColumns[columnKey].taskIds.includes(enquiry.id.toString())) {
            newColumns[columnKey].taskIds.push(enquiry.id.toString());
          }
        }
      });
  
      setState({
        tasks: newTasks,
        columns: newColumns,
        columnOrder: initialData.columnOrder,
      });
  
      setLoading(false);
    } catch (error) {
      console.error("Error fetching enquiries:", error);
      setLoading(false);
    }
  };
  
  
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      Object.values(state.tasks).forEach((task) => {
        if (
          task.details.status === "Connected" &&
          task.details.statuschangetime
        ) {
          const now = new Date();
          const statuschangetime = new Date(task.details.statuschangetime); // or parseISO(task.details.statuschangetime);
          console.log("Parsed statuschangetime:", statuschangetime);

          const timeDifference = now - statuschangetime; // Difference in milliseconds

          // Convert milliseconds to hours
          const hoursInStatus = timeDifference / (1000 * 60 * 60);

          if (hoursInStatus >= 72) {
            // Change the salesbook_status to 'lost pre-tutor'
            const updatedTask = {
              ...task,
              details: {
                ...task.details,
                salesbook_status: "lost pre-tutor",
              },
            };

            setState((prevState) => ({
              ...prevState,
              tasks: {
                ...prevState.tasks,
                [task.id]: updatedTask,
              },
            }));

            // Update status on the server
            updateEnquiryStatus(task.id, "lost pre-tutor");
          }
        }
      });
    }, 60 * 60 * 1000); // Check every hour

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [state.tasks]);

 
  

  

  const onDragEnd = async (result) => {
    console.log("DragEnd triggered:", result);
  
    const { destination, source, draggableId } = result;
  
    if (!destination) {
      console.log("No destination found. Drag cancelled.");
      return;
    }
  
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      console.log("Dragged item dropped in the same position. No action taken.");
      return;
    }
  
    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];
  
    if (!start || !finish) {
      console.error("Start or Finish column not found in state:", {
        start,
        finish,
      });
      return;
    }
  
    let newState = { ...state };
  
    if (start === finish) {
      console.log("Moving within the same column");
  
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);
  
      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };
  
      newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };
    } else {
      console.log("Moving to a different column");
  
      const startTaskIds = Array.from(start.taskIds);
      startTaskIds.splice(source.index, 1);
      const newStart = {
        ...start,
        taskIds: startTaskIds,
      };
  
      const finishTaskIds = Array.from(finish.taskIds);
      finishTaskIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        taskIds: finishTaskIds,
      };
  
      newState = {
        ...state,
        columns: {
          ...state.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };
    }
  
    const newStatus = Object.keys(statuses).find(
      (status) => getColumnKeyFromStatus(status) === destination.droppableId
    );
  
    const oldStatus = state.tasks[draggableId].details.status;
    const statuschangetime = oldStatus !== newStatus 
      ? new Date().toISOString() // New status, update time
      : state.tasks[draggableId].details.statuschangetime; // Keep old time if status unchanged
  
    const updatedTask = {
      ...state.tasks[draggableId],
      details: {
        ...state.tasks[draggableId].details,
        status: newStatus,
        statuschangetime, // Correct time for the status change
      },
    };
  
    console.log("Updated task:", updatedTask);
  
    setState({
      ...newState,
      tasks: {
        ...newState.tasks,
        [draggableId]: updatedTask,
      },
    });
  
    console.log(`Updating enquiry status to ${newStatus} for enquiry ${draggableId}`);
  
    // Call the backend to update the status
    await updateEnquiryStatus(draggableId, newStatus, statuschangetime);
  };
  
  
  

  const updateEnquiryStatus = async (enquiryId, newStatus, statuschangetime) => {
    try {
      // Prepare the data to be sent to the backend
      const updatedEnquiry = {
        status: newStatus,
        statuschangetime, // Send statuschangetime to the backend
      };
  
      // Update the enquiry status in the backend
      await axios.put(`/api/enquiries/${enquiryId}`, updatedEnquiry);
  
      console.log(`Enquiry ${enquiryId} status successfully updated to ${newStatus}`);
    } catch (error) {
      console.error("Error updating enquiry status:", error);
    }
  };
  
  
  

  const updateClientStatus = async (clientId, newStatus) => {
    try {
      await axios.put(`/api/clients/${clientId}`, { lead_status: newStatus });
    } catch (error) {
      console.error("Error updating client status:", error);
    }
  };

  const openDialog = (client = null) => {
    setCurrentClient(client);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setCurrentClient(null);
  };

  const saveEnquiry = async (enquiry) => {
    try {
      await axios.post(`/api/clients/${enquiry.client_id}/enquiries`, enquiry);
      fetchEnquiries(); // Refresh enquiries
    } catch (error) {
      console.error("Error saving enquiry:", error);
    }
  };

  const openEnquiryDialog = () => {
    if (clients.length > 0) {
      setIsEnquiryDialogOpen(true);
    } else {
      console.error("No clients available to select");
      // You can open the dialog anyway for testing
      setIsEnquiryDialogOpen(true);
    }
  };

  const takeLead = async (clientId) => {
    try {
      if (!loggedInUser?.email) {
        console.error("Logged in user email not found");
        return;
      }
  
      // Filter out timestamps older than 1 hour
      const oneHourAgo = new Date().getTime() - 60 * 60 * 1000;
      const recentTakenLeads = takenLeads.filter((timestamp) => timestamp > oneHourAgo);
  
      if (recentTakenLeads.length >= 5) {
        console.error("You have reached the maximum of 5 leads within the past hour.");
        setShowLimitModal(true); // Show the modal instead of using an alert
        return;
    }
  
      // Use the logged-in user's email to assign them as the client manager
      await axios.put(`/api/clients/${clientId}`, { client_manager: loggedInUser.email });
  
      // Update the state to reflect the change
      setState((prevState) => {
        const updatedTasks = { ...prevState.tasks };
  
        if (!updatedTasks[clientId.toString()] || !updatedTasks[clientId.toString()].details) {
          console.error(`Task not found for client ID: ${clientId}`);
          return prevState;
        }
  
        // Update the client manager in the task details
        updatedTasks[clientId.toString()] = {
          ...updatedTasks[clientId.toString()],
          details: {
            ...updatedTasks[clientId.toString()].details,
            clientManager: loggedInUser.email,
          },
        };
  
        return {
          ...prevState,
          tasks: updatedTasks,
        };
      });
  
      console.log(`Client manager for client ${clientId} updated to ${loggedInUser.email}`);
  
      // Update the list of timestamps for leads taken
      setTakenLeads([...recentTakenLeads, new Date().getTime()]);
  
      // Optionally, re-fetch the enquiries to ensure everything is in sync
      fetchEnquiries();
    } catch (error) {
      console.error("Error taking lead:", error);
    }
  };
  

  useEffect(() => {
    if (loggedInUser?.email) {
      // Load taken leads for the specific logged-in user from localStorage
      const storedLeads = JSON.parse(localStorage.getItem(`takenLeads_${loggedInUser.email}`)) || [];
      setTakenLeads(storedLeads);
    }
  }, [loggedInUser]);
  
  useEffect(() => {
    if (loggedInUser?.email) {
      // Store taken leads for the specific logged-in user in localStorage whenever it changes
      localStorage.setItem(`takenLeads_${loggedInUser.email}`, JSON.stringify(takenLeads));
    }
  }, [takenLeads, loggedInUser]);
  
  
  
  
  
  const assignClientManager = async (clientId, clientManagerEmail) => {
    try {
      await axios.put(`/api/clients/${clientId}`, { client_manager: clientManagerEmail || null });
  
      // Update the state to reflect the change
      setState((prevState) => {
        // Create a deep copy of the tasks
        const updatedTasks = { ...prevState.tasks };
  
        // Ensure the task exists before attempting to update it
        if (!updatedTasks[clientId.toString()] || !updatedTasks[clientId.toString()].details) {
          console.error(`Task not found for client ID: ${clientId}`);
          return prevState; // Return the previous state if the task is not found
        }
  
        // Update the client manager in the task details
        updatedTasks[clientId.toString()] = {
          ...updatedTasks[clientId.toString()],
          details: {
            ...updatedTasks[clientId.toString()].details,
            clientManager: clientManagerEmail || null, // Use null for unassigned
          },
        };
  
        return {
          ...prevState,
          tasks: updatedTasks, // Use the updated tasks object
        };
      });
  
      console.log(`Client manager for client ${clientId} updated to ${clientManagerEmail || 'Unassigned'}`);
      
      // Optionally, re-fetch the enquiries to make sure everything is in sync
      fetchEnquiries();
    } catch (error) {
      console.error("Error assigning client manager:", error);
    }
  };
  
  
  
  
  


  const closeEnquiryDialog = () => {
    setIsEnquiryDialogOpen(false);
  };

  const saveClient = async (client) => {
    if (client.id) {
      // Edit existing client
      try {
        await axios.put(`/api/clients/${client.id}`, client);

        const updatedTask = {
          id: client.id.toString(),
          content: `${client.first_name} ${client.last_name}`,
          details: client,
        };
        const columnKey = getColumnKeyFromStatus(client.lead_status);
        setState((prevState) => {
          const newTasks = {
            ...prevState.tasks,
            [client.id.toString()]: updatedTask,
          };
          const newColumns = { ...prevState.columns };
          Object.keys(newColumns).forEach((key) => {
            newColumns[key].taskIds = newColumns[key].taskIds.filter(
              (id) => id !== client.id.toString()
            );
          });
          if (!newColumns[columnKey].taskIds.includes(client.id.toString())) {
            // Check for duplicate
            newColumns[columnKey].taskIds.push(client.id.toString());
          }
          return { ...prevState, tasks: newTasks, columns: newColumns };
        });
      } catch (error) {
        console.error("Error editing client:", error);
      }
    } else {
      // Add new client
      try {
        const response = await axios.post("/api/clients", client);
        const newClient = response.data;
        const newTask = {
          id: newClient.id.toString(),
          content: `${newClient.first_name} ${newClient.last_name}`,
          details: newClient,
        };
        const columnKey = getColumnKeyFromStatus(newClient.lead_status);
        setState((prevState) => ({
          ...prevState,
          tasks: { ...prevState.tasks, [newClient.id.toString()]: newTask },
          columns: {
            ...prevState.columns,
            [columnKey]: {
              ...prevState.columns[columnKey],
              taskIds: [
                ...prevState.columns[columnKey].taskIds.filter(
                  (id) => id !== newClient.id.toString()
                ),
                newClient.id.toString(),
              ], // Check for duplicate
            },
          },
        }));
      } catch (error) {
        console.error("Error adding client:", error);
      }
    }
    closeDialog();
  };

  const markAsLost = async (enquiryId) => {
    try {
      // Update the enquiry's salesbook_status and status to 'Lost Pre-Tutor'
      const updatedEnquiry = {
        salesbook_status: "Lost Pre-Tutor",
        status: "Lost Pre-Tutor" // Ensure status is also updated
      };
      await axios.put(`/api/enquiries/${enquiryId}`, updatedEnquiry);
  
      // Update the local state by removing the enquiry from the pipeline
      setState((prevState) => {
        const updatedTasks = { ...prevState.tasks };
        delete updatedTasks[enquiryId]; // Remove the lost enquiry
  
        const updatedColumns = { ...prevState.columns };
        Object.keys(updatedColumns).forEach((columnId) => {
          updatedColumns[columnId].taskIds = updatedColumns[columnId].taskIds.filter(id => id !== enquiryId);
        });
  
        return { ...prevState, tasks: updatedTasks, columns: updatedColumns };
      });
  
      console.log(`Enquiry ${enquiryId} marked as 'Lost Pre-Tutor' and removed from pipeline`);
    } catch (error) {
      console.error("Error marking enquiry as 'Lost Pre-Tutor':", error.message);
    }
  };
  
  
  
  const openEnquiryInfoModal = async (enquiryId) => {
    try {
      const response = await axios.get(`/api/enquiry-details/${enquiryId}`);
  
      // Log the entire API response to ensure fields are returned correctly
      console.log('API Response:', response.data);
  
      // Check if the fields you expect are available
      console.log('frequency_duration:', response.data.frequency_duration);
      console.log('details:', response.data.details);
  
      // Set the state only if the data is present
      setEnquiryInfo({
        frequency: response.data.frequency_duration || 'Not provided',
        details: response.data.details || 'No details available',
      });
  
      // Open the modal
      setIsEnquiryInfoModalOpen(true);
    } catch (error) {
      console.error('Error fetching enquiry info:', error);
    }
  };
  
  

  const deleteClient = async (client) => {
    try {
      await axios.delete(`/api/clients/${client.id}`);
      const newTasks = { ...state.tasks };
      delete newTasks[client.id];

      const newColumns = { ...state.columns };
      Object.keys(newColumns).forEach((columnId) => {
        newColumns[columnId].taskIds = newColumns[columnId].taskIds.filter(
          (id) => id !== client.id.toString()
        );
      });

      setState({
        ...state,
        tasks: newTasks,
        columns: newColumns,
      });
    } catch (error) {
      console.error(
        "Error deleting client:",
        error.response?.data || error.message
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900">
          Pipeline
        </h1>

        <div className="mt-3 flex sm:ml-4 sm:mt-0">
          <input
            type="text"
            placeholder="Search Pipeline"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mr-4 border border-gray-300 rounded-md px-3 py-2 text-sm w-64" // Adjust width for better appearance
          />
          <button
            onClick={() => openDialog()}
            type="button"
            className="mr-4 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Add New Client
          </button>
          <button
            onClick={openEnquiryDialog}
            type="button"
            className="mr-4 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Add New Enquiry
          </button>

        


          <button
            onClick={() => setFilter("all")}
            className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
              filter === "all"
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            }`}
          >
            Show All Enquiries
          </button>

          <button
            onClick={() => setFilter("new")} // Set filter to show leads
            className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
              filter === "new"
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            }`}
          >
            Show From Leads
          </button>

          <button
            onClick={() => setFilter("existing")} // Set filter to show clients
            className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
              filter === "existing"
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            }`}
          >
            Show From Clients
          </button>

          {["Admin", "Assistant"].includes(loggedInUser?.role) && (
            <select
              value={clientManagerFilter}
              onChange={(e) => setClientManagerFilter(e.target.value)}
              className="mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <option value="all">All Managers</option>
              {users.map((user) => (
                <option key={user.id} value={user.email}>
                  {user.name || user.email}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex gap-4 p-6">
          {state.columnOrder
            .filter((columnId) => {
              // Conditionally hide columns 'Connected' and 'First Lesson Complete'
              if (
                !showColumns &&
                (columnId === "column-5" || columnId === "column-6")
              ) {
                return false;
              }
              return true;
            })
            .map((columnId) => {
              const column = state.columns[columnId];
              const tasks = (column.taskIds || []).map(
                (taskId) => state.tasks[taskId]
              );

              return (
                <div
                  key={column.id}
                  className="flex-1 bg-gray-100 p-4 rounded-lg shadow"
                >
                  <h2 className="text-lg font-bold mb-4">{column.title}</h2>
                  <Droppable droppableId={column.id} key={column.id}>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="overflow-hidden rounded-xl mb-2"
                      >
                        {tasks
                          .filter((task) => {
                            if (!task || !task.details) return false; // Safeguard: Ensure task and details exist

                            // Apply filter logic for search term
                            const taskDetails = task.details || {};
                            const clientName = taskDetails.clientName || "";
                            const jobId = taskDetails.job_id || "";
                            const email = taskDetails.email || "";
                            const phone = taskDetails.phone || "";

                            // Check if any field contains the search term (case-insensitive)
                            const matchesSearchTerm =
                              clientName
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              jobId.toString().includes(searchTerm) ||
                              email
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              phone
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase());

                            // Apply existing new/existing filter logic
                            if (filter === "new") {
                              return (
                                matchesSearchTerm &&
                                !task.details.tutorcruncher_id
                              );
                            }
                            if (filter === "existing") {
                              return (
                                matchesSearchTerm &&
                                task.details.tutorcruncher_id
                              );
                            }
                            return matchesSearchTerm; // Default case if no specific filter
                          })
                          .slice(0, visibleTasksCount)
                          .map((task, index) => {
                            if (!task || !task.details) return null; // Safeguard: Ensure task and details exist

                            const isExpanded = expandedCards[task.id]; // Safeguard task.id

                            return (
                              <Draggable
                                key={task.id}
                                draggableId={task.id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    className="overflow-hidden rounded-xl border border-gray-200 mb-2"
                                  >
                                    {/* Title and click to expand */}
                                    <div
                                      className="flex items-center gap-x-4 border-b border-gray-900/5 bg-white p-6 cursor-pointer"
                                      onClick={() => toggleCardExpand(task.id)} // Toggle expand on click
                                    >
                                      <div className="text-sm font-medium leading-6 text-gray-900 flex flex-col">
                                        <div className="flex items-center">
                                          <span
                                            className={`inline-block w-2.5 h-2.5 rounded-full mr-2 ${
                                              task.details.tutorcruncher_id
                                                ? "bg-green-500"
                                                : "bg-orange-500"
                                            }`}
                                          ></span>
                                          {task.content}
                                        </div>
                                        <div className="text-xs text-gray-500">
                                          Created {timeSince(task.details.created_at)}
                                        </div>
                                        <div className="text-xs text-gray-500">
                                        Status last changed {timeSince(task.details.statuschangetime)}

                                        <button
                onClick={(e) => {
                    e.stopPropagation(); // Prevent propagation to the parent element
                    markAsLost(task.details.id); // Call the function to mark the enquiry as "Lost Pre-Tutor"
                }}
                className="ml-2 text-xs text-blue-500 hover:underline"
            >
                Mark Lost
                </button>
                                        </div>

                                      </div>

                                      <Menu
  as="div"
  className="relative ml-auto"
>
  <MenuButton
    className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500"
    onClick={(e) => {
      e.stopPropagation(); // Prevent card collapse
      toggleCardExpand(task.id, true); // Force expand the card
    }}
  >
    <span className="sr-only">Open options</span>
    <EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5" />
  </MenuButton>
  <MenuItems
    className="absolute right-0 z-50 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
    onClick={(e) => e.stopPropagation()} // Prevent menu click from collapsing the card
  >
    <MenuItem>
      <Link
        to={`/clients/${task.details.client_id}`}
        className="block px-3 py-1 text-sm leading-6 text-gray-900"
        onClick={(e) => e.stopPropagation()} // Prevent propagation
      >
        Client Details
        <span className="sr-only">, {task.content}</span>
      </Link>
    </MenuItem>
    <MenuItem
  as="button"
  onClick={async (e) => {
    e.stopPropagation();
    await openEnquiryInfoModal(task.details.id); // Pass the enquiry ID to fetch the info
  }}
  className="block px-3 py-1 text-sm leading-6 text-gray-900"
>
  Enquiry Info
</MenuItem>

    {loggedInUser?.role === "Client Manager" &&
      task.details.status === "New Enquiry" &&
      !task.details.clientManager && (
        <MenuItem
          as="button"
          onClick={(e) => {
            e.stopPropagation();
            takeLead(task.details.client_id);
          }}
          className="block px-3 py-1 text-sm leading-6 text-gray-900"
        >
          Take Lead
        </MenuItem>
      )}

    <MenuItem
      as="button"
      onClick={async (e) => {
        e.stopPropagation();
        await markAsLost(task.details.id);
      }}
      className="block px-3 py-1 text-sm leading-6 text-gray-900"
    >
      Mark Lost
    </MenuItem>
  </MenuItems>
</Menu>



                                    </div>

                                    {/* Conditionally show card body */}
                                    {isExpanded && (
                                      <dl className="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 bg-white">
                                        <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Student
                                          </dt>
                                          <dd className="text-gray-700">
                                            {task.details.student_first_name}{" "}
                                            {task.details.student_last_name}
                                          </dd>
                                        </div>
                                        <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Email
                                          </dt>
                                          <dd className="text-gray-700">
                                            {task.details.email}
                                          </dd>
                                        </div>
                                        <div className="gap-x-4 py-3">
  <dt className="text-gray-500">Client Manager</dt>
 {/* Show only the client manager's name if the user is a 'Client Manager' */}
 {loggedInUser?.role === "Client Manager" && (
    <dd className="text-gray-700">
      {task.details.clientManager}
    </dd>
  )}
  
  {["Admin"].includes(loggedInUser?.role) && (
  <dd className="text-gray-700">
    <select
      value={task.details.clientManager || ""} // Set to empty string if no client manager
      onChange={(e) => {
        const selectedValue = e.target.value || null; // Use `null` for the empty option
        assignClientManager(task.details.client_id, selectedValue);
      }}
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
    >
      <option value="">Select a client manager</option> {/* This option represents 'unassigned' */}
      {users.map((user) => (
        <option key={user.id} value={user.email}>
          {user.name || user.email}
        </option>
      ))}
    </select>
  </dd>
)}

</div>

                                        <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Job ID
                                          </dt>
                                          <dd className="text-gray-700">
                                            {task.details.job_id}
                                          </dd>
                                        </div>
                                        <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Phone
                                          </dt>
                                          <dd className="text-gray-700">
                                            {task.details.phone}
                                          </dd>
                                        </div>
                                        <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Source
                                          </dt>
                                          <dd className="text-gray-700">
                                            {task.details.lead_source}
                                          </dd>
                                        </div>
                                        <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Status
                                          </dt>
                                          <dd className="flex items-start gap-x-2">
                                            <div
                                              className={classNames(
                                                statuses[task.details.status],
                                                "rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                              )}
                                            >
                                              {task.details.status}
                                            </div>
                                          </dd>
                                        </div>
                                      </dl>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {tasks.length > visibleTasksCount && (
                          <button
                            onClick={loadMoreTasks}
                            className="mt-4 w-full rounded-md bg-blue-500 text-white py-2"
                          >
                            Load More
                          </button>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              );
            })}
        </div>
      </DragDropContext>

      <ClientDialog
        isOpen={isDialogOpen}
        closeModal={closeDialog}
        client={currentClient}
        saveClient={saveClient}
      />
      <EnquiryDialog
  isOpen={isEnquiryDialogOpen}
  closeModal={closeEnquiryDialog}
  saveEnquiry={saveEnquiry}
  clients={clients} // Ensure the clients array is passed correctly
/>

<Dialog
    open={showLimitModal}
    onClose={() => setShowLimitModal(false)}
    className="relative z-10"
>
    <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
    >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>

    <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Limit Reached
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    You have reached the maximum of 5 leads within the past hour. Please wait before taking more leads.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            onClick={() => setShowLimitModal(false)}
                            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                        >
                            OK
                        </button>
                    </div>
                </Dialog.Panel>
            </Transition.Child>
        </div>
    </div>
</Dialog>

<Dialog
  open={isEnquiryInfoModalOpen}
  onClose={() => setIsEnquiryInfoModalOpen(false)}
  className="relative z-10"
>
  <Transition.Child>
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  </Transition.Child>

  <div className="fixed inset-0 z-10 overflow-y-auto">
    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <Transition.Child>
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
              <EllipsisHorizontalIcon className="h-6 w-6 text-blue-600" />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                Enquiry Information
              </Dialog.Title>
              <div className="mt-2">
              

                <p className="text-sm text-gray-500">
                  <strong>Frequency:</strong> {enquiryInfo.frequency}
                </p>
                <p className="text-sm text-gray-500 mt-1">
                  <strong>Details:</strong> {enquiryInfo.details}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
              onClick={() => setIsEnquiryInfoModalOpen(false)}
            >
              Close
            </button>
          </div>
        </Dialog.Panel>
      </Transition.Child>
    </div>
  </div>
</Dialog>




    </div>
  );
};

export default Pipeline;
