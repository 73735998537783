import React, { useState } from "react";
import { Listbox } from '@headlessui/react';
import { PaperClipIcon, TagIcon, UserCircleIcon, CalendarIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/20/solid'


const labels = [
  { name: 'Unlabelled', value: null },
  { name: 'Urgent', value: 'urgent' },
  { name: 'High Priority', value: 'high_priority' },
  { name: 'No Response', value: 'no_response' },
];

const dueDates = [
  { name: 'No due date', value: null },
  { name: 'Today', value: new Date().toISOString() },
  { name: 'Tomorrow', value: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString() },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NoteForm = ({
  onSave,
  note_text,
  setNoteText,
  assigned,
  setAssigned,
  labelled,
  setLabelled,
  dated,
  setDated,
  assignees,
  noteType, // Use the noteType passed from ClientDetails or NoteEditModal
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      note_text,
      type: noteType, // Pass the noteType to the onSave function
      assigned,
      labelled,
      dated,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="relative">


      <div className="mt-8 overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
        <label htmlFor="note_text" className="sr-only">
          Note Text
        </label>
        <textarea
  id="note_text"
  name="note_text"
  rows={4}
  placeholder={`Write a ${(noteType || 'Note').toLowerCase()}...`}  // Fallback to 'Note' if noteType is undefined
  value={note_text}
  onChange={(e) => setNoteText(e.target.value)}
  className="block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pt-4 pl-4"
/>


        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-9" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
      </div>

      {/* Only show Listboxes if noteType is 'Task' */}
      {noteType === 'Task' && (
        <div className=" inset-x-px bottom-0">
          <div className="flex flex-nowrap justify-end space-x-2 px-2 py-2 sm:px-3">
          <Listbox as="div" value={assigned} onChange={setAssigned} className="flex-shrink-0">
  {/* Button to trigger the Listbox */}
  <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
    <UserCircleIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-300 sm:-ml-1" />
    <span className={classNames(assigned.value === null ? '' : 'text-gray-900', 'hidden truncate sm:ml-2 sm:block')}>
      {assigned.value === null ? 'Assign' : assigned.name}
    </span>
  </Listbox.Button>

  {/* Scrollable Listbox options */}
  <Listbox.Options className="absolute z-10 mt-1 max-h-24 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
    {assignees.map((assignee) => (
      <Listbox.Option
        key={assignee.value}
        value={assignee}
        className="relative cursor-default select-none bg-white px-3 py-2 hover:bg-gray-100"
      >
        <div className="flex items-center">
          <UserCircleIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
          <span className="ml-3 block truncate font-medium">{assignee.name}</span>
        </div>
      </Listbox.Option>
    ))}
  </Listbox.Options>
</Listbox>


            <Listbox as="div" value={labelled} onChange={setLabelled} className="flex-shrink-0">
              <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                <TagIcon aria-hidden="true" className={classNames(labelled.value === null ? 'text-gray-300' : 'text-gray-500', 'h-5 w-5 flex-shrink-0 sm:-ml-1')} />
                <span className={classNames(labelled.value === null ? '' : 'text-gray-900', 'hidden truncate sm:ml-2 sm:block')}>
                  {labelled.value === null ? 'Label' : labelled.name}
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute z-10 mt-1 max-h-24 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {labels.map((label) => (
                  <Listbox.Option key={label.value} value={label} className="relative cursor-default select-none bg-white px-3 py-2 data-[focus]:bg-gray-100">
                    <div className="flex items-center">
                      <span className="block truncate font-medium">{label.name}</span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>

            <Listbox as="div" value={dated} onChange={setDated} className="flex-shrink-0">
              <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                <CalendarIcon aria-hidden="true" className={classNames(dated.value === null ? 'text-gray-300' : 'text-gray-500', 'h-5 w-5 flex-shrink-0 sm:-ml-1')} />
                <span className={classNames(dated.value === null ? '' : 'text-gray-900', 'hidden truncate sm:ml-2 sm:block')}>
                  {dated.value === null ? 'Due date' : dated.name}
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {dueDates.map((dueDate) => (
                  <Listbox.Option key={dueDate.value} value={dueDate} className="relative cursor-default select-none bg-white px-3 py-2 data-[focus]:bg-gray-100">
                    <div className="flex items-center">
                      <span className="block truncate font-medium">{dueDate.name}</span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          </div>
        </div>
      )}

      <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
        <div className="flex">
          
        </div>
        <div className="flex-shrink-0">
          <button
            type="submit"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save {noteType}
          </button>
        </div>
      </div>
    </form>
  );
};

export default NoteForm;
