import React, { useEffect, useState, useMemo  } from "react";
import axios from "axios";
import SalesBookDialog from "./SalesBookDialog";
import { Link } from "react-router-dom";
import { BellIcon, ChevronDownIcon, CheckCircleIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { Box, Typography, Select, MenuItem } from "@mui/material";



import Popover from '@mui/material/Popover';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin

// Use the plugins with dayjs
dayjs.extend(utc);
dayjs.extend(timezone);


// Custom header component for dropdown filters below headers
const CustomHeaderWithDropdown = ({ label, options, onFilterChange, filterValue }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
      <span>{label}</span>
      <Select
        value={filterValue || ""}
        onChange={(e) => onFilterChange(e.target.value)}
        displayEmpty
        variant="standard"
        sx={{ fontSize: "0.875rem", minWidth: 120 }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};




const SalesBook = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tab, setTab] = useState("won"); // 'won', 'lost', or 'connected'
  const [editingRow, setEditingRow] = useState(null); // Track which row is being edited
  const [editedData, setEditedData] = useState({}); // Store edited data
  const [showAllColumns, setShowAllColumns] = useState(false); // Control additional columns visibility
  const [clientManagers, setClientManagers] = useState([]); // Store unique client managers
  const [selectedManager, setSelectedManager] = useState(""); // Selected client manager for filtering
  const [loading, setLoading] = useState(false); // Manage loading state
  const [filteredClients, setFilteredClients] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null); // Logged in user info
  const [showRecentUpdates, setShowRecentUpdates] = useState(false); // New filter state
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [jobStatusFilter, setJobStatusFilter] = useState(""); // Job Status filter state
  const [firstLessonPlannedFilter, setFirstLessonPlannedFilter] = useState(""); // First Lesson Planned filter state
  const [milestoneFilter, setMilestoneFilter] = useState(""); // Milestone filter state



  const [firstLessonPlannedMap, setFirstLessonPlannedMap] = useState({});

  

  const [sortModel, setSortModel] = useState([
    {
      field: 'created_on_tc_at',
      sort: 'desc', // 'desc' for newest to oldest
    },
  ]);
  

   // Extract unique job statuses from the clients data
   const uniqueJobStatuses = useMemo(() => {
    const statuses = clients.map((client) => client.job_status);
    return [...new Set(statuses)]; // Get unique statuses
  }, [clients]);

  // Handle filters for Job Status, First Lesson Planned, and Milestones
  const handleJobStatusFilterChange = (newStatus) => {
    setJobStatusFilter(newStatus);
    applyFilters(newStatus, firstLessonPlannedFilter, milestoneFilter);
  };

  const handleFirstLessonPlannedFilterChange = (newValue) => {
    setFirstLessonPlannedFilter(newValue);
    applyFilters(jobStatusFilter, newValue, milestoneFilter);
  };

  const handleMilestoneFilterChange = (newMilestone) => {
    setMilestoneFilter(newMilestone);
    applyFilters(jobStatusFilter, firstLessonPlannedFilter, newMilestone);
  };

  const applyFilters = (jobStatus, firstLessonPlanned, milestone) => {
    let filtered = clients;
  
    // Apply Job Status filter only if a specific status is selected
    if (jobStatus && jobStatus !== "") {
      filtered = filtered.filter((client) => client.job_status === jobStatus);
    }
  
    // Apply First Lesson Planned filter only if "Yes" or "No" is selected
    if (firstLessonPlanned !== "") {
      filtered = filtered.filter(
        (client) =>
          (firstLessonPlanned === "Yes" && client.first_lesson_planned) ||
          (firstLessonPlanned === "No" && !client.first_lesson_planned)
      );
    }
  
    // Apply Milestone filter
    if (milestone) {
      filtered = filtered.filter((client) => client.stage === milestone);
    }
  
    setFilteredClients(filtered);
  };
  

   // Milestone options
   const milestoneOptions = [
    { value: "", label: "All Milestones" },
    { value: "No Lessons Complete", label: "No Lessons Complete" },
    { value: "First Lesson Complete", label: "First Lesson Complete" },
    { value: "2 Lessons Complete", label: "2 Lessons Complete" },
    { value: "3 Lessons Complete", label: "3 Lessons Complete" },
    { value: "5 Lessons Complete", label: "5 Lessons Complete" },
    { value: "10 Lessons Complete", label: "10 Lessons Complete" },
    { value: "20 Lessons Complete", label: "20 Lessons Complete" },
  ];

  const firstLessonPlannedOptions = [
    { value: "", label: "All" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  
  const jobStatusOptions = [
    { value: "", label: "All" },
    ...uniqueJobStatuses.map((status) => ({
      value: status,
      label: status,
    })),
  ];
  
  

  useEffect(() => {
    setFilteredClients(clients); // By default, show all clients when no milestone is selected
  }, [clients]);
  
  
  
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().startOf("year"), // Start of the current year
    endDate: dayjs().endOf("year"), // End of the current year
  });
  
  const [filterModel, setFilterModel] = useState({
    items: [{ columnField: 'created_on_tc_at', operatorValue: 'contains', value: '' }],
  });
 
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetchEnquiries();
  }, [dateRange]); // Fetch enquiries whenever the date range changes


  

  const shortcutsItems = [
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("week"), today.endOf("week")];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, "day"), today];
      },
    },
    {
      label: "Last Month",
      getValue: () => {
        const today = dayjs();
        const lastMonth = today.subtract(1, "month");
        return [lastMonth.startOf("month"), lastMonth.endOf("month")];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "This Year",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("year"), today.endOf("year")];
      },
    },
    {
      label: "Last Year",
      getValue: () => {
        const today = dayjs();
        const lastYear = today.subtract(1, "year");
        return [lastYear.startOf("year"), lastYear.endOf("year")];
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ];

  
  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }
  
        const response = await axios.get("/api/current-user", {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        setLoggedInUser(response.data);
        console.log("Logged in user:", response.data);
      } catch (error) {
        console.error("Error fetching logged-in user:", error);
      }
    };
  
    fetchLoggedInUser();
  
    // Fetch initial "won" data once when the component mounts
    fetchEnquiries();
  }, []); // Empty dependency array to ensure this runs once on mount

  useEffect(() => {
    // Fetch enquiries whenever tab, selectedManager, or showRecentUpdates change
    if (loggedInUser) {
      fetchEnquiries();
    }
  }, [tab, selectedManager, showRecentUpdates, loggedInUser]); // Include loggedInUser in the dependencies
  

  

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const twelveHoursAgo = new Date(Date.now() - 12 * 60 * 60 * 1000);


  const fetchEnquiries = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/salesbook", {
        params: {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
          tab: tab,
          manager: selectedManager,
          role: loggedInUser?.role,
          email: loggedInUser?.email,
          showRecentUpdates: showRecentUpdates,
        },
      });
  
      const enquiriesWithProfit = await Promise.all(
        response.data.enquiries.map(async (enquiry) => {
          if (tab === "connected") {
            await checkAndUpdateFirstLessonPlanned(enquiry.job_id);
          }
          // Ensure you're fetching the updated first_lesson_planned field from the backend
          return {
            ...enquiry,
            first_lesson_planned: enquiry.first_lesson_planned || false,
            in_progress_date: enquiry.in_progress_date,
            package_profit: parseFloat(enquiry.package_profit).toFixed(2),
            calculated_profit: parseFloat(enquiry.calculated_profit).toFixed(2),
          };
        })
      );
  
      setClients(enquiriesWithProfit);
      setClientManagers(response.data.clientManagers);
    } catch (error) {
      console.error("Error fetching salesbook data:", error.message);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  
  const checkAndUpdateFirstLessonPlanned = async (jobId) => {
    try {
      // Fetch the first lesson planned status from your local database
      const response = await axios.get(`/api/enquiries/${jobId}/first-lesson-planned`);
      
      // Update the local state or handle accordingly
      console.log(`First lesson planned status for job ${jobId}:`, response.data.firstLessonPlanned);
    } catch (error) {
      console.error(`Error fetching first lesson for job ${jobId}:`, error.message);
    }
  };
  
  

  const handleDateRangeChange = (newValue) => {
    if (newValue && newValue[0] && newValue[1]) {
      setDateRange({
        startDate: newValue[0].startOf("day"),
        endDate: newValue[1].endOf("day"),
      });
    }
  };

  const openDatePicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDatePicker = () => {
    setAnchorEl(null);
  };

  const handleShortcutClick = (shortcut) => {
    let newRange;
    switch (shortcut) {
      case "This Week":
        newRange = [dayjs().startOf('week'), dayjs().endOf('week')];
        break;
      case "Last Week":
        newRange = [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')];
        break;
      case "This Month":
        newRange = [dayjs().startOf('month'), dayjs().endOf('month')];
        break;
      case "Last Month":
        newRange = [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')];
        break;
      default:
        return;
    }
    setDateRange(newRange);
    closeDatePicker(); // Close the picker after selecting a shortcut
  };


  const columns = [
    {
      field: "markAsSeen",
      headerName: "Has Updates",
      width: 180,
      renderCell: (params) => (
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
          {!params.row.seen && (
            <button
              onClick={() => markAsSeen(params.row.id)}
              className="inline-flex items-center px-2 py-1 text-xs font-medium text-green-700 bg-green-50 rounded-md hover:bg-green-100"
            >
              <CheckCircleIcon className="h-4 w-4 mr-1" />
              Mark as Seen
            </button>
          )}
        </td>
      ),
    },
    { field: "job_id", headerName: "Job ID", width: 150,
      renderCell: (params) => (
        <Link to={`https://app.tutorchase.com/cal/service/${params.row.job_id}`} className="text-blue-600 hover:underline">
          {params.value}
        </Link>
      ),
     },
    {
      field: "created_on_tc_at",
      headerName: "Created on TC",
      width: 150,
      renderCell: (params) => {
        const dateInLondon = dayjs(params.value).tz("Europe/London").format('DD/MM/YYYY HH:mm');
        return <span>{dateInLondon}</span>;
      },
    },
    {
      field: "in_progress_date",
      headerName: "Connected",
      width: 150,
      renderCell: (params) => {
        const dateInLondon = params.value ? dayjs(params.value).tz("Europe/London").format('DD/MM/YYYY HH:mm') : "N/A";
        return <span>{dateInLondon}</span>;
      },
    },
    
    
    
    {
      field: "clientManagerName", // Ensure this matches the backend
      headerName: "Client Manager",
      width: 150,
    },
    {
      field: "full_name",
      headerName: "Full Name",
      width: 150,
      renderCell: (params) => (
        <Link to={`/clients/${params.row.client_id}`} className="text-blue-600 hover:underline">
          {params.value}
        </Link>
      ),
    },
    
    {
      field: "stage",
      headerName: "Milestone",
      width: 180,
      renderHeader: () => (
        <CustomHeaderWithDropdown
          label="Milestone"
          options={milestoneOptions}
          onFilterChange={handleMilestoneFilterChange}
          filterValue={milestoneFilter}
        />
      ),
    },   

    
    
    {
      field: "job_status",
      headerName: "Job Status",
      width: 150,
      renderHeader: () => (
        <CustomHeaderWithDropdown
          label="Job Status"
          options={jobStatusOptions}
          onFilterChange={handleJobStatusFilterChange}
          filterValue={jobStatusFilter}
        />
      ),
    },   
    { field: "charge_rate", headerName: "Client Cost £", width: 120 },
    { field: "tutor_rate", headerName: "Tutor Pay £", width: 120 },
    { field: "totallessonhourscomplete", headerName: "Complete Hours", width: 150 },
    { field: "profit", headerName: "Lessons Profit £", width: 140 },
    { field: "package_profit", headerName: "Package Profit £", width: 160 },
    { field: "calculated_profit", headerName: "Total Profit £", width: 120 },
    {
      field: "packageExists",
      headerName: "Package",
      width: 120,
      renderCell: (params) => (
        <span
          className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${
            params.value === "Yes"
              ? "bg-green-100 text-green-800" // Blue for "Yes"
              : "bg-gray-100 text-gray-800" // Gray for "No"
          }`}
        >
          {params.value}
        </span>
      ),
    },
    
    { field: "tutorcruncher_id", headerName: "Client TC ID", width: 150 },
    {
      field: "predicted_lessons",
      headerName: "Predicted Lessons",
      width: 150,
      renderCell: (params) => (
        editingRow === params.row.id ? (
          <input
            type="number"
            value={editedData[params.row.id]?.predicted_lessons || params.row.predicted_lessons}
            onChange={(e) => handleInputChange(e, "predicted_lessons", params.row.id)}
            className="w-full px-2 py-1 border rounded"
          />
        ) : (
          params.row.predicted_lessons || "N/A"
        )
      ),
    },
    { field: "totalLessonsComplete", headerName: "Total Lessons Complete", width: 150 },
    {
      field: "predictionAccuracy",
      headerName: "Prediction Accuracy",
      width: 180,
      renderCell: (params) => (
        <span
          className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${
            params.row.predicted_lessons === null
              ? "bg-gray-100 text-gray-800" // Gray for "No Prediction"
              : params.row.totalLessonsComplete >= params.row.predicted_lessons
              ? "bg-green-100 text-green-800" // Green for "Accurate Prediction"
              : "bg-red-100 text-red-800" // Red for "Inaccurate Prediction"
          }`}
        >
          {params.row.predicted_lessons === null
            ? "No Prediction"
            : calculatePredictionAccuracy(params.row.predicted_lessons, params.row.totalLessonsComplete)}
        </span>
      ),
    },
    
    { field: "avglessonlength", headerName: "Avg Lesson Length", width: 150 },
    { field: "revenue", headerName: "Revenue £", width: 120 },
    { field: "package", headerName: "Package", width: 120 },
    { field: "cost", headerName: "Cost £", width: 120 },
    ...(showAllColumns
      ? [
          { field: "email", headerName: "Email", width: 200 },
          { field: "phone", headerName: "Phone", width: 150 },
          { field: "country", headerName: "Country", width: 150 },
          { field: "customer_type", headerName: "Customer Type", width: 150 },
          { field: "form_host", headerName: "Form Host", width: 150 },
          { field: "gclid", headerName: "GCLID", width: 150 },
          { field: "lead_source", headerName: "Lead Source", width: 150 },
          { field: "how_did_they_hear_about_us", headerName: "How Did They Hear About Us", width: 200 },
          { field: "approx_hours", headerName: "Approx Hours", width: 150 },
          { field: "school_year", headerName: "School Year", width: 150 },
          { field: "budget", headerName: "Budget", width: 120 },
          { field: "hascontractor", headerName: "Has Tutor", width: 150 },
          { field: "subject", headerName: "Subject", width: 150 },
          { field: "vat", headerName: "VAT", width: 100 },
          { field: "comms", headerName: "Comms", width: 120 },
          { field: "formId", headerName: "Form ID", width: 150 },
          { field: "landPage", headerName: "Landing Page", width: 200 },
        ]
      : []), // Add additional columns conditionally
      
    {
      field: "salesbook_status",
      headerName: "Salesbook Status",
      width: 150,
      renderCell: (params) => (
        <select
          value={params.value || "N/A"}
          onChange={(e) => handleInputChange(e, "salesbook_status", params.row.id)}
          className="w-full px-2 py-1 border rounded"
        >
          <option value="N/A">N/A</option>
          <option value="Connected">Connected</option>
          <option value="Won">Won</option>
          <option value="Lost Pre-Tutor">Lost Pre-Tutor</option>
          <option value="Lost Post-Tutor">Lost Post-Tutor</option>
        </select>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        editingRow === params.row.id ? (
          <>
            <button
              onClick={() => saveInlineEdit(params.row.client_id, params.row.id)}
              className="text-green-600 hover:text-green-900 mr-2"
            >
              Save
            </button>
            <button
              onClick={cancelInlineEdit}
              className="text-red-600 hover:text-red-900"
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            onClick={() => setEditingRow(params.row.id)}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Edit Predicted Lessons
          </button>
        )
      ),
    },
  ];
  
  const allColumns = [...columns]; // Clone the base columns

  if (tab === 'connected') {
    const firstLessonPlannedColumn = {
      field: "first_lesson_planned",
      headerName: "First Lesson Planned",
      width: 180,
      renderHeader: () => (
        <CustomHeaderWithDropdown
          label="First Lesson Planned"
          options={firstLessonPlannedOptions}
          onFilterChange={handleFirstLessonPlannedFilterChange}
          filterValue={firstLessonPlannedFilter}
        />
      ),
      renderCell: (params) => <span>{params.value ? "Yes" : "No"}</span>,
    };
  
    // Find the index of the "Milestone" column
    const milestoneIndex = allColumns.findIndex((col) => col.field === "stage");
  
    // Insert the "First Lesson Planned" column right after the "Milestone" column
    if (milestoneIndex !== -1) {
      allColumns.splice(milestoneIndex + 1, 0, firstLessonPlannedColumn);
    }
  }
  
  

  
  

  const handleManagerChange = (e) => {
    setSelectedManager(e.target.value); // Update the manager filter based on the selected value
  };

  const handleInputChange = (event, fieldName, enquiryId) => {
    const { value } = event.target;
    setEditedData((prev) => ({
      ...prev,
      [enquiryId]: {
        ...prev[enquiryId],
        [fieldName]: value, // Update the specific field (e.g., predicted_lessons, salesbook_status)
      },
    }));
  };
  
  const saveInlineEdit = async (clientId, enquiryId) => {
    const dataToUpdate = {
      ...editedData[enquiryId],
      predicted_lessons: parseInt(editedData[enquiryId]?.predicted_lessons, 10),
    };
    
    try {
      // Send the POST request to update the predicted lessons
      await axios.post(`/api/enquiries/${enquiryId}/predicted-lessons`, { predicted_lessons: dataToUpdate.predicted_lessons });
      
      // Update the enquiry data in the local state without refetching
      setClients((prevClients) =>
        prevClients.map((enquiry) =>
          enquiry.id === enquiryId
            ? { 
                ...enquiry, 
                ...dataToUpdate,
                predictionAccuracy: calculatePredictionAccuracy(
                  dataToUpdate.predicted_lessons,
                  enquiry.totalLessonsComplete
                ) // Recalculate prediction accuracy
              }
            : enquiry
        )
      );
  
      setEditingRow(null);
    } catch (error) {
      console.error("Error saving enquiry data:", error.message);
    }
  };
  
  
  
// Function to calculate prediction accuracy
const calculatePredictionAccuracy = (predictedLessons, totalLessonsComplete) => {
  if (predictedLessons === null || predictedLessons === undefined) {
    return "No Prediction";
  }
  return totalLessonsComplete >= predictedLessons ? "Accurate Prediction" : "Inaccurate Prediction";
};


  const handleFetchAndUpdate = async () => {
    setLoading(true);
    try {
      await axios.post("/api/local/sync-data");
      await fetchEnquiries();
    } catch (error) {
      console.error("Error fetching and updating data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  
  
  const handleRecountSalesbookStats = async () => {
    setLoading(true);
    try {
      // Call the backend endpoint to recount salesbook stats
      await axios.post('/api/enquiries/recount-stats');
      
      // Refresh the enquiries list after the recount is completed
      await fetchEnquiries();
    } catch (error) {
      console.error('Error recounting salesbook stats:', error.message);
    } finally {
      setLoading(false);
    }
  };
  
  

  const toggleShowAllColumns = () => {
    setShowAllColumns((prevShowAllColumns) => !prevShowAllColumns);
  };

  const cancelInlineEdit = () => {
    setEditingRow(null); // Reset editing state
  };

  const handleSaveClient = async (formData) => {
    console.log("Saving client with data:", formData); // Log the data being sent
    if (!formData) {
      console.error("No form data found. Exiting save operation.");
      return;
    }
    try {
      const response = await axios.put(
        `/api/clients/${formData.client_id}`,
        formData
      );
      console.log("Save response:", response.data); // Log the response data
      await fetchEnquiries(); // Re-fetch data after saving to update the UI
    } catch (error) {
      console.error("Error saving client:", error.message); // Log the error message
      console.error(
        "Error details:",
        error.response?.data || "No additional error details"
      ); // Log any additional error details
    }
  };

  const fetchClientData = async (clientId) => {
    try {
      console.log(`Fetching client data for client ID: ${clientId}`); // Log the clientId being used
      const response = await axios.get(`/api/clients/${clientId}`);
      console.log("Client data fetched from server:", response.data); // Log the response from the server
      return response.data;
    } catch (error) {
      console.error("Error fetching client data:", error.message);
      return {};
    }
  };

  const fetchEnquiryData = async (jobId) => {
    try {
      // Fetch clientCost and tutorPay from the backend
      const serviceResponse = await axios.get(
        `/api/tutorcruncher/job-details/${jobId}`
      );
      const { status, dft_charge_rate, dft_contractor_rate } =
        serviceResponse.data; // Ensure these keys are correct

      console.log(`Job Details for Job ID ${jobId}:`, serviceResponse.data);

      // Fetch appointments to calculate total lesson hours, revenue, etc.
      const appointmentsResponse = await axios.get(
        `/api/tutorcruncher/appointments`,
        {
          params: {
            serviceId: jobId,
            start_gte: "", // Add your start date if needed
            start_lte: "", // Add your end date if needed
          },
        }
      );

      const appointments = appointmentsResponse.data.results;
      let totalLessonsComplete = 0;
      let totallessonhourscomplete = 0;

      appointments.forEach((appointment) => {
        const startTime = new Date(appointment.start);
        const endTime = new Date(appointment.finish);

        if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
          const hours = (endTime - startTime) / (1000 * 60 * 60); // Convert milliseconds to hours
          totalLessonsComplete += 1;
          totallessonhourscomplete += hours;
        }
      });

      const avgLessonLength =
        totalLessonsComplete > 0
          ? (totallessonhourscomplete / totalLessonsComplete).toFixed(1)
          : "0.0";

      const clientCost = dft_charge_rate || 0;
      const tutorPay = dft_contractor_rate || 0;

      const revenue = clientCost * totallessonhourscomplete; // Calculate revenue
      const cost = tutorPay * totallessonhourscomplete; // Calculate tutor pay
      const profit = (revenue - cost).toFixed(2); // Calculate profit and then apply toFixed
      const packageprofit = (revenue - cost).toFixed(2); // Calculate profit and then apply toFixed
      const calculatedprofit = (revenue - cost).toFixed(2); // Calculate profit and then apply toFixed

      return {
        totalLessonsComplete,
        totallessonhourscomplete: totallessonhourscomplete.toFixed(1),
        avgLessonLength,
        revenue,
        cost,
        profit,
        clientCost, // Directly using client cost per hour
        tutorPay, // Directly using tutor pay per hour
        jobStatus: status || "Unknown",
      };
    } catch (error) {
      console.error(
        "Error fetching service or appointment data:",
        error.message
      );
      return {
        totalLessonsComplete: 0,
        totallessonhourscomplete: "0.0",
        avgLessonLength: "0.0",
        revenue: "0.00",
        cost: "0.00",
        profit: "0.00",
        clientCost: 0,
        tutorPay: 0,
        jobStatus: "Unknown",
      };
    }
  };

  const fetchTutorName = async (serviceId) => {
    try {
      const response = await axios.get(
        `/api/tutorcruncher/services/${serviceId}/tutor`
      );
      console.log(
        `Tutor Name for Service ID ${serviceId}:`,
        response.data.tutorName
      );
      return response.data.tutorName;
    } catch (error) {
      console.error("Error fetching tutor name:", error.message);
      return "N/A";
    }
  };

  const fetchJobDetails = async (jobId) => {
    try {
      // Fetch job details, including job status, dft_charge_rate, and dft_contractor_rate
      const response = await axios.get(
        `/api/tutorcruncher/job-details/${jobId}`
      );

      const { status, dft_charge_rate, dft_contractor_rate } = response.data;

      console.log(`Job Details for Job ID ${jobId}:`, {
        status,
        dft_charge_rate,
        dft_contractor_rate,
      });

      return {
        status,
        clientCost: dft_charge_rate,
        tutorPay: dft_contractor_rate,
      };
    } catch (error) {
      console.error(
        `Error fetching job details for Job ID ${jobId}:`,
        error.message
      );
      return {
        status: "Unknown",
        clientCost: 0,
        tutorPay: 0,
      };
    }
  };

  // const calculateStage = (totalLessonsComplete) => {
  //   if (totalLessonsComplete >= 10) return "10 Lessons Complete";
  //   if (totalLessonsComplete >= 5) return "5 Lessons Complete";
  //   if (totalLessonsComplete >= 3) return "3 Lessons Complete";
  //   if (totalLessonsComplete >= 1) return "First Lesson Complete";
  //   return "No Lessons Complete";
  // };

  const handleDeleteClient = async (clientId) => {
    try {
      await axios.delete(`/api/clients/${clientId}`);
      setClients(clients.filter((client) => client.id !== clientId));
    } catch (error) {
      console.error("Error deleting client:", error.message);
    }
  };

  const openEditDialog = (client) => {
    console.log("Client data passed to dialog:", client); // Ensure this logs the full client object
    setSelectedClient(client);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedClient(null);
  };

  const JobStatusLabel = ({ status }) => {
    const getStatusStyle = (status) => {
      switch (status) {
        case "pending":
          return "bg-gray-100 text-gray-800";
        case "available":
          return "bg-blue-100 text-blue-800";
        case "in-progress":
          return "bg-green-100 text-green-800";
        case "finished":
          return "bg-orange-100 text-orange-800";
        case "gone-cold":
          return "bg-red-100 text-red-800";
        default:
          return "bg-gray-100 text-gray-800";
      }
    };

    return (
      <span
        className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${getStatusStyle(
          status
        )}`}
      >
        {status}
      </span>
    );
  };

  const saveClient = async (clientData) => {
    try {
      console.log("Saving client with data:", clientData);
      const response = await axios.put(
        `http://localhost:3000/api/clients/${clientData.client_id}`,
        clientData
      );
      console.log("Client updated successfully:", response.data);
    } catch (error) {
      if (error.response) {
        // Log details if the server responded with a status other than 2xx
        console.error("Error response from server:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  

  const markAsSeen = async (enquiryId) => {
    try {
      // Update the local 'seen' status in your local database
      await axios.put(`/api/enquiries/${enquiryId}`, { seen: true });
  
      // Update the local state to reflect the change immediately without refetching
      setClients((prevClients) =>
        prevClients.map((enquiry) =>
          enquiry.id === enquiryId ? { ...enquiry, seen: true } : enquiry
        )
      );
    } catch (error) {
      console.error("Error marking enquiry as seen:", error.message);
    }
  };
  
  
  
  
  
  
  
  

  return (
    <div className="mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900">Salesbook</h1>
        
      {/* Button to open date picker */}
<button
  onClick={() => setShowDatePicker((prev) => !prev)}
  className="px-4 py-2 border rounded"
>
  {dateRange.startDate.format('MMM DD, YYYY')} - {dateRange.endDate.format('MMM DD, YYYY')}
</button>

{/* Popover to show the StaticDateRangePicker */}
<Popover
  open={showDatePicker}
  anchorEl={anchorEl}
  onClose={() => setShowDatePicker(false)}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
>
  <div className="p-4">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDateRangePicker
        displayStaticWrapperAs="desktop"
        value={[dateRange.startDate, dateRange.endDate]}
        onChange={handleDateRangeChange}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          actionBar: { actions: [] },
        }}
        calendars={2}
      />
    </LocalizationProvider>
  </div>
</Popover>

      

        <div className="flex space-x-4">
        <button onClick={() => setTab("won")} className={`px-4 py-2 font-semibold rounded ${tab === "won" ? "bg-blue-500 text-white" : "bg-gray-200"}`}>Won</button>
        <button onClick={() => setTab("connected")} className={`px-4 py-2 font-semibold rounded ${tab === "connected" ? "bg-blue-500 text-white" : "bg-gray-200"}`}>Connected</button>
        <button onClick={() => setTab("lost")} className={`px-4 py-2 font-semibold rounded ${tab === "lost" ? "bg-red-500 text-white" : "bg-gray-200"}`}>Lost</button>


          {/* {loggedInUser?.role === "Admin" && (
           <select
           value={selectedManager}
           onChange={handleManagerChange}
           className="px-4 py-2 font-semibold rounded border border-gray-300"
         >
           <option value="">All Managers</option>
           {clientManagers.map((manager) => (
             <option key={manager.id} value={manager.first_name}>
               {manager.first_name}
             </option>
           ))}
         </select>
         
          )} */}

         
          <button
  onClick={() => setShowRecentUpdates((prev) => !prev)}
  className="px-4 py-2 font-semibold bg-yellow-500 text-white rounded"
>
  {showRecentUpdates ? "Show All Entries" : "Show Entries with Updates"}
</button>


          <button
            onClick={toggleShowAllColumns}
            className="px-4 py-2 font-semibold bg-green-500 text-white rounded"
          >
            {showAllColumns ? "Hide Columns" : "Show All Columns"}
          </button>

         


        </div>
      </div>

    
      {loading ? (
        <div className="text-center">
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="spinner-border animate-spin inline-block w-12 h-12 border-8 rounded-full border-t-blue-500 border-blue-300 mb-4"></div>
            <p className="text-xl font-semibold text-center">
              Loading {tab} salesbook entries...
            </p>
          </div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          
        </div>
      )}

      <SalesBookDialog
        isOpen={isDialogOpen}
        closeModal={closeDialog}
        client={selectedClient}
        saveClient={saveClient}
        onSave={handleSaveClient} // Pass the callback to refresh data
      />

<DataGridPro
    rows={filteredClients} // Use filtered clients

  columns={allColumns}
  pagination
  pageSizeOptions={[5, 10, 25, 50]}
  paginationModel={paginationModel}
  sortModel={sortModel}
  onSortModelChange={(newModel) => setSortModel(newModel)}
  onPaginationModelChange={(model) => setPaginationModel(model)}
  getRowId={(row) => row.id}
  filterModel={filterModel} // Connect the filter model
  onFilterModelChange={(newModel) => {
    if (JSON.stringify(newModel) !== JSON.stringify(filterModel)) {
      setFilterModel(newModel); // Only update when there's an actual change
    }
  }}
/>





<button
            onClick={handleFetchAndUpdate}
            className="px-4 py-2 font-semibold bg-blue-500 text-white rounded mr-4 mt-4"
          >
            Fetch & Update from TutorCruncher
          </button>

          <button
  onClick={handleRecountSalesbookStats}
  className="px-4 py-2 font-semibold bg-purple-500 text-white rounded mt-4"
>
  Recount Salesbook Stats
</button>


    </div>
  );
};

export default SalesBook;
